import { COLLECTION_NAME_MAP } from '@/domain/env/collection-name-map';
import { CollectionBase } from '@/domain/types/collections';
import { Column } from '@/presentation/components/table';

export const columns: Column<CollectionBase>[] = [
  { header: 'Título', accessor: 'title' },
  {
    header: 'Tipo',
    accessor: 'type',
    render: (value) => {
      return (
        <span className="text-sm">
          {COLLECTION_NAME_MAP[value as keyof typeof COLLECTION_NAME_MAP]}
        </span>
      );
    },
  },
  {
    header: 'Creado el',
    accessor: 'createdAt',
    render: (value) => {
      if (value && typeof value === 'object' && 'seconds' in value) {
        return (
          <span className="text-sm">
            {new Date(value.seconds! * 1000).toLocaleDateString('es-CO', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </span>
        );
      }
      return <span className="text-sm">Desconocido</span>;
    },
  },
  {
    header: 'Vistas',
    accessor: 'views',
    isSortable: true,
    render: (value) => (
      <div className="flex justify-center items-center">
        <span className="text-sm">
          {value && typeof value === 'number' ? value : 0}
        </span>
      </div>
    ),
  },
  {
    header: 'Descargas',
    accessor: 'downloads',
    isSortable: true,
    render: (value) => (
      <div className="flex justify-center items-center">
        <span className="text-sm">
          {value && typeof value === 'number' ? value : 0}
        </span>
      </div>
    ),
  },
  {
    header: 'Favoritos',
    accessor: 'totalFavorites',
    isSortable: true,
    render: (value) => (
      <div className="flex justify-center items-center">
        <span className="text-sm">
          {value && typeof value === 'number' ? value : 0}
        </span>
      </div>
    ),
  },
  {
    header: 'Comentarios',
    accessor: 'totalComments',
    isSortable: true,
    render: (value) => (
      <div className="flex justify-center items-center">
        <span className="text-sm">
          {value && typeof value === 'number' ? value : 0}
        </span>
      </div>
    ),
  },
];

import { db } from '@/data/firebase/config';
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  Unsubscribe,
} from 'firebase/firestore';
import React, { useCallback, useEffect } from 'react';

const useGetDocuments = <T>(document: string) => {
  const [data, setData] = React.useState<T | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const unsubscribeRef = React.useRef<Unsubscribe | null>(null);

  const fetchData = useCallback(async () => {
    console.log('fetchData');
    setIsLoading(true);
    if (unsubscribeRef.current) {
      unsubscribeRef.current();
    }
    const queryRef = query(
      collection(db, document),
      orderBy('createdAt', 'desc'),
    );

    unsubscribeRef.current = onSnapshot(
      queryRef,
      (snapshot) => {
        const newData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as T;

        setData(newData);
        setIsLoading(false);
      },
      (error) => {
        console.error(error);
        setError(error.message);
        setIsLoading(false);
      },
    );
  }, [document]);

  useEffect(() => {
    fetchData();
    return () => {
      if (unsubscribeRef.current) {
        unsubscribeRef.current();
      }
    };
  }, [fetchData]);

  return { data, isLoading, error };
};

export default useGetDocuments;

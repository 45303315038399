import { db } from '@/data/firebase/config';
import { DOCUMENTS_DB } from '@/domain/env/documents-db';
import { CollectionBase } from '@/domain/types/collections';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { useCallback, useState } from 'react';

const useDashboard = () => {
  const [data, setData] = useState<CollectionBase[]>([]);
  const [totalFavorites, setTotalFavorites] = useState<number>(0);
  const [totalComments, setTotalComments] = useState<number>(0);
  const [totalViews, setTotalViews] = useState<number>(0);
  const [totalDownloads, setTotalDownloads] = useState<number>(0);

  const fetchData = useCallback(async () => {
    try {
      const queries = [
        query(
          collection(db, DOCUMENTS_DB.NEWSLETTERS),
          orderBy('createdAt', 'desc'),
        ),
        query(
          collection(db, DOCUMENTS_DB.POLICIES),
          orderBy('createdAt', 'desc'),
        ),
        query(
          collection(db, DOCUMENTS_DB.REPORTS),
          orderBy('createdAt', 'desc'),
        ),
        query(
          collection(db, DOCUMENTS_DB.RELEASES),
          orderBy('createdAt', 'desc'),
        ),
        query(
          collection(db, DOCUMENTS_DB.CRITICAL_STANDARS),
          orderBy('createdAt', 'desc'),
        ),
        query(
          collection(db, DOCUMENTS_DB.PODCASTS),
          orderBy('createdAt', 'desc'),
        ),
        query(
          collection(db, DOCUMENTS_DB.VIDEOS),
          orderBy('createdAt', 'desc'),
        ),
      ];

      const queriesSnapshot = await Promise.all(queries.map((q) => getDocs(q)));

      const results = queriesSnapshot.flatMap((snapshot, index) => {
        const collectionName = [
          DOCUMENTS_DB.NEWSLETTERS,
          DOCUMENTS_DB.POLICIES,
          DOCUMENTS_DB.REPORTS,
          DOCUMENTS_DB.RELEASES,
          DOCUMENTS_DB.CRITICAL_STANDARS,
          DOCUMENTS_DB.PODCASTS,
          DOCUMENTS_DB.VIDEOS,
        ][index];

        return snapshot.docs.map((doc) => ({
          id: doc.id,
          ...(doc.data() as CollectionBase),
          type: collectionName,
        }));
      });

      setTotalFavorites(
        results.reduce((acc, curr) => acc + (curr.favorites || 0), 0),
      );
      setTotalComments(
        results.reduce((acc, curr) => acc + (curr.comments || 0), 0),
      );
      setTotalViews(results.reduce((acc, curr) => acc + (curr.views || 0), 0));
      setTotalDownloads(
        results.reduce((acc, curr) => acc + (curr.downloads || 0), 0),
      );

      setData(results);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return {
    data,
    fetchData,
    totalFavorites,
    totalComments,
    totalViews,
    totalDownloads,
  };
};

export default useDashboard;

import { Column } from '@/presentation/components/table';
import { User } from '../types/user';

export const columnsUser: Column<User>[] = [
  {
    header: 'Documento',
    accessor: 'document',
  },
  {
    header: 'Nombre',
    accessor: 'name',
  },
  {
    header: 'Apellido',
    accessor: 'lastname',
  },
  {
    header: 'Email',
    accessor: 'email',
  },
  {
    header: 'Departamento',
    accessor: 'department',
  },
  {
    header: 'Area',
    accessor: 'area',
  },
  {
    header: 'Cargo',
    accessor: 'position',
  },
  {
    header: 'Unidad',
    accessor: 'unit',
  },
  {
    header: 'Rol',
    accessor: 'role',
    render: (value) => {
      switch (value) {
        case 'administrator':
          return 'Administrador';
        case 'employee':
          return 'Empleado';
      }
    },
  },
  {
    header: 'Estado',
    accessor: 'isEnabled',
    render: (value) => {
      switch (value) {
        case true:
          return <span className="text-green-600 font-semibold">Activo</span>;
        case false:
          return <span className="text-red-600 font-semibold">Inactivo</span>;
      }
    },
  },
];
